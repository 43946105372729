.setting-cards {
  width: 100%;
  background-color: white;
  box-shadow: 0px 10px 20px -4px rgba(0, 0, 0, 0.1);
  padding: 20px 30px 20px;
  margin-top: 10px;
  transition: height 2s;
}

.setting-title-container {
  display: flex;
  justify-content: space-between;
  font-size: larger;
  font-weight: bold;
}
.setting-sublist {
  list-style-type: none;
}

.setting-list-container {
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.list-arrow {
  transform: rotate(0deg);
}
.rotate {
  transform: rotate(180deg);
}
.span {
  font-weight: bolder;
}
.listCard {
  border-bottom: 2px solid rgb(188, 185, 185);
  font-size: medium;
  transition: padding 2s;
  padding: 10px 20px;
}
.Listctive-card {
  border-bottom: 2px solid rgb(188, 185, 185);
  background-color: rgb(213, 245, 213);
  font-size: large;
  transition: padding 2s;
  padding: 15px 25px;
}
.listCard:hover {
  font-size: larger;
  transition: font-size 0.5s;
}
.System-table {
  border: 2px solid red;
  height: auto;
  width: 30rem;
}

/* --------------- Pop Up --------------- */
.popup_modal {
  width: 70%;
  height: 10px;
  position: fixed;
  top: 30px;
  z-index: 1;
  margin-left: 15%;
}
.popup_modal_edit {
  width: 70%;
  height: 10px;
  position: fixed;
  top: 30px;
  z-index: 1;
  margin-left: 20%;
}
.popup_modal_delete {
  width: 70%;
  height: 10px;
  position: fixed;
  top: 30px;
  z-index: 1;
  margin-left: 20%;
}
.popup_main_delete {
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 0px 0px 1000px rgba(0, 0, 0, 0.75);
  background-color: #eeeeee;
  height: 180px;
  width: 55%;
  margin-top: -10px;
  padding-left: 20px;
  padding-top: 50px;
}
.popup_main_delete h3 {
  text-align: center;
}

.popupclose{
  display: flex;
font-size: large;
  justify-content:space-between;
  z-index: 1;
  color: rgb(0, 0, 0);
  cursor: pointer;
  border-bottom: 1px solid #636363;
  margin-bottom: 2em;
}
.Addtitle{
  background-color: rgb(44, 44, 246);
  padding:5px;
  color: #ffffff;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  border-radius: 5px;
}

.x-button-report .x-press-report:hover {
  transform: scale(1.04);
}

.popup_main {
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 0px 0px 1000px rgba(0, 0, 0, 0.75);
  background-color: #ffffff;
  height: 250px;
  width: 50%;
  margin-top: -10px;
  padding-left: 20px;
  padding-top: 50px;
}

.popupform input {
  position: relative;
  height: 40px;
  width: 80%;
  outline: none;
  border: 1px solid rgb(159, 157, 157);
  border-radius: 6px;
  padding: 0 15px;
  margin-left: 50px;
  margin-right: 50px;
}

.popupform input:focus {
  box-shadow: 0px 0px 2px 1px rgba(21, 113, 146, 0.4);
  border: 1px solid rgba(21, 113, 146, 0.4);
}

.send_button {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.popup_add {
  padding:  10px;
  border: none;
  border-radius: 7px;
  color: rgb(0, 0, 0);
box-shadow:  2px 0px 2px 2px rgb(140, 231, 176);  width: 120px;
}


.popup_cancle {
  padding: 1% 5% 1% 5%;
  border: none;
  border-radius: 7px;
  color: rgb(0, 0, 0);
  width: 120px;
  box-shadow: 2px 0px 2px 2px rgb(248, 165, 165);
}
.popup_cancle:hover, .popup_add:hover{
  width: 130px;
  transition: 0s ease-in-out;
}

.qwess {
  background-color: white;
}

.popup-innerq{
  position: relative;
  padding:25px;
  width: 100%;
  max-width: 500px;
  background-color: rgb(244, 244, 244);
  z-index: 1;
  border-radius: 10px;
  border: none;
}
.popup0 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(50, 50, 50, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
