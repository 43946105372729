body{
  margin: 0;
  padding: 0;

  }
  .SigninWrapper{
      display: flex;
      max-width: 70%;
      width: 50%;
      min-width: 300px;
      height: 60vh;
      border-radius:2em;
      margin: 100px auto;
      box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.1),
      -6px -6px 12px rgba(255, 255, 255, 0.5);
      overflow: hidden;

  }
  .signinLogo {
    width: 100%; 
    max-width: 200px; 
    height: auto; 
    display: block; 
    margin: 0 auto; 
    margin-bottom: 20px;
  }
  
  .confirmWrapper{
    display: flex;
    max-width: 85%;
    width: 80%;
    min-width: 300px;
    height: 90vh;
    border-radius:2em; 
    margin: 20px auto;
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.1),
    -6px -6px 12px rgba(255, 255, 255, 0.5);
}
  label, input{
  display: block;
  }

  .SigninForm{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width:100%;
      height: 100%;
  }
  .left-side{
    display: none;

  }
  .left-side p {
font-size: 15px;  
  }
  .signInBold{
    display: block;
    font-weight: bolder;
    margin-bottom: 1em;
  }
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
  }
  .Signin{
  width: 90%;
  align-items: center;
  }
  .signInput{
      width: 95%;
      border: 0.1px solid rgb(216, 213, 213);
      height: 45px;
      border-radius: .75rem;
      margin-bottom: 10px;
      padding: .5rem;
      background-color:rgb(226, 242, 247)
  
  }
  .signintitle{
    margin-bottom: 1em;
  }
  input:focus{
    outline: none;
  }
  label{
        font-weight: bolder;
  }
  .signin-div{
    margin-top: 1em;
    width: 80%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .signIn-btn{
      padding: .5em;
      border-radius: .5em;
      box-shadow: 1px 2px 0px .5px  rgb(177, 194, 175);
      text-align: center;
      font-size: larger;
      font-weight:bold ;
      background-color: rgb(187, 252, 187);
      border: none;
  }
  .signIn-btn:hover{
    background-color: rgb(187, 252, 187);
    padding: .6em;
    transition: all ease-in-out;
  }
  .eye{
      width: 35px;
      height: 20px;
      color: rgb(164, 161, 161);
      cursor: pointer;
    }
    #passInput, #ConfpassInput{
      height: 80%;
      border: none;
      margin-top: 23px;
    }
    .passWrapper{
      display: flex;
      align-items: center;
      height: 45px;
      width: 95%;
      border: 0.1px solid rgb(216, 213, 213);
      border-radius: .75rem;
      margin-bottom: 25px;
      padding: .5rem;
      background-color: rgb(226, 242, 247)
  
    }
  
  /******************************/
  
    @media screen and (min-width: 500px) {
    
      .SigninWrapper{
          display: block;
      }
    }
   
    @media screen and (min-width: 700px) {
      /* CSS rules for larger screens go here */
      .SigninWrapper{
          width: 70%;
          display: flex;
          
       }
     .SigninForm{
      margin: 0 auto;
      justify-content: center;
      width: 50%;
     }
      .signIn-btn{
          width: 60%;
      }
      .left-side {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1em;
        width: 40%;
        height: 100%;
        border-radius:2em 0 0 2em ;
        background-color:rgb(247, 240, 240);
      }
      .confirmWrapper{
        margin: 30px auto;
        width: 70% ;

       }
    }
    @media screen and (min-width: 1000px) {
      .confirmWrapper{
        margin: 30px auto;
        width: 50% ;

       }
      .SigninWrapper{
        width: 50%;
       
     }
    }
   