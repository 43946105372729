.driver-name {
  text-align: center;
  margin-bottom: 15px;
  width: 100%;
  display: block;
  height: 35px;
  border-bottom: 0.4px solid black;
  padding-top: 5px;
}
.manage-modal-content.detail-content {
  height: 90vh;
  overflow-y: scroll;
}
.manage-modal-content.detail-content::-webkit-scrollbar {
  width: 5px;
}
.manage-modal-content.detail-content::-webkit-scrollbar-thumb {
  background-color: rgb(192, 192, 192);
}
.detail-content label {
  font-weight: normal;
  padding-left: 5px;
}
.detail-content input {
  height: 40px;
  text-align: left;
  padding-left: 10px;
}
.detail-content .detail-part {

  align-items:center ;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 1px;
}
.registrationChoicee{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.registrationChoicee button{
  margin: .5em;
  padding: 1.5em;
  border-radius: .5em;
  font-size: medium;
}
.Active {
  font-size: 2em;
  color: rgb(3, 64, 117);
  transition: font-size 1s;

}
.link{
  font-size: 1em;
  transition: font-size 1s;
  text-decoration: none;
  color: black;
}
.detail-content hr {
  margin-bottom: 20px;
}
.flex-grow {
  flex-grow: 1;
}
@media (min-width: 990px) {
  .manage-modal-content.detail-content {
    max-width: 500px;
  }
}
@media (max-width: 560px) {
  .manage-modal-content.detail-content {
    width: 90vw;
  }
}
