@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700;800;900&display=swap");
.table-container {
  height: 300px;
  overflow-y: auto;
  overflow-x: auto;
  border:2px solid yello;
}


.data-table td {
  padding: 5px;
  font-size: 12px;

}
.data-table th, .data-table-report th {
  padding: 10px;
  font-size: 12px;
  font-weight: 300;
  background-color: rgba(8, 80, 170);
  color: white;
  text-align: left;
}
.data-table tr, .data-table-report tr {
  border-top: 0.4px solid rgb(197, 197, 197);
  border-bottom: 0.4px solid rgb(197, 197, 197);
  border-radius: 4px 10px;
}

.data-table {
  border-collapse: collapse;
  margin-top: 20px;
  margin: 10px auto;
  font-family: "Poppins", sans-serif;
}

.data-table tr:nth-child(even), .data-table-report tr:nth-child(even) {
  background-color: rgb(220, 220, 220);
}
.table-btn {
  border: none;
  padding: 8px 15px;
  background-color: rgb(79, 81, 83);
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 10px;
  font-weight: bold;
  width: 100%;
}
.table-btn:hover {
  background-color: rgb(5, 103, 201);
}
.top-card-holder {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}
.setting-card-holder {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  gap: .5em;
  justify-content: space-around;
}
.img-licence:hover {
  position: absolute;
  left: -10%;
  top: -80%;
  scale: 1.5;
  width: 300px !important;
  height: 400px !important;
  object-fit: contain !important;
  cursor: pointer;
  padding-bottom: 5px !important;
}
.paginationAndReport{
  display:flex;
  justify-content: space-between;
  margin-top: 1em;
  
}
.printBtn{
  color: white;
  padding:8px 15px;
  border-radius: .3em;
  background-color: blue;
  border: none;
}
@media (max-width: 992px) {
  .table-container {
    max-width: 600px;
  }
  .main-bar-content {
    max-width: 90vw;
  }
  .top-card-holder {
    display: none;
  }
  .data-table, .data-table-report {
    max-width: 500px;
  }
  .table-container {
    overflow-x: scroll;
  }
  .data-table td , .data-table-report td{
    padding: 5px;
  }
  .data-table th, .data-table-report th {
    padding: 5px;
  }
}
