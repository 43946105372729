.sidebar-containers {
    width: 200px;
  }
  .sidebar-wrappers{
    position: fixed;
    width: 300px;
    background-color:white;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
    overflow-y: auto;
  }
  .sidebar-wrappers .sidebar-lists{
    padding-top: 15px;
    list-style-type: none;
    display: flex;
    row-gap: 30px;
    flex-direction: column;
    align-items: center;
  
  }
  .sidebar-wrappers .sidebar-lists li{
    width: 100%;
    height: 40px;
    font-size: 16px;
    gap: 12px;
    display: flex;
    align-items: center;
    flex-direction: row;
    color:black;
    margin-left: 50px;
    cursor: pointer;

  }
  .sidebar-lists {
     padding-top: 15px;
    list-style-type: none;
    display: flex;
    row-gap: 20px;
    flex-direction: column;
    align-items: center;
    
  }
  
  .sidebar-lists li {
    display: flex;
    align-items: center;
  }
  
  .sidebar-lists li p {
    margin-left: 10px; /* Add some left margin to create space between the icon and the text */
  }
  
  
  .sidebar-containers .sidebar-lists li{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 130px;
    height: 60px;
    font-size: 14px;
    color:black;
    border-bottom: 1px solid rgb(216, 213, 213);
  
  }
  .menu{
    color: black;
    display: flex;
    justify-content: space-around;
    background-color:rgb(247, 247, 247); 
    opacity: 0%;
   
  }
  .ActiveSideBars{
    font-size: 2em ; 
    transition: font-size 1s;
    color: rgb(8, 80, 170);
  }
  .sideBars:hover{
    font-size: 2em ; 
    transition: font-size 1s;
  }
  .sideBars{
    font-size: 1.5rem;
    transition: font-size 1s;
 
  }
  .sideBarr{
    font-size: 2rem;
   
  }
  .listtop{
    margin-bottom: 10px;
    margin-top: 5px;
  }
 
