.sidebar-container {
  width: 200px;
}
.sidebar-wrapper{
  position: fixed;
  width: 300px;
  background-color: rgb(217, 226, 245);
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 90%;
  z-index: 100;
    
}
.sidebar-wrapper .sidebar-list{
  padding-top: 20px;
  list-style-type: none;
  display: flex;
  row-gap: 20px;
  flex-direction: column;
  align-items: center;
  border: 2px solid red;
}
.sidebar-items{
  padding-top: 50px;

}
.sidebar-wrapper .sidebar-list li{
  width: 100%;
  height: 40px;
  font-size: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color:black;
  border-bottom: 1px solid rgb(216, 213, 213);
}
.sidebar-list {
  padding-top: 100px;
  list-style-type: none;
  display: flex;
  row-gap: 20px;
  flex-direction: column;
  align-items: center;
}


.sidebar-container .sidebar-list li{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 130px;
  height: 60px;
  font-size: 14px;
  color:black;
  border-bottom: 1px solid rgb(216, 213, 213);

}
.menu{
  color: black;
  display: flex;
  justify-content: space-around;
  background-color:rgb(247, 247, 247); 
  opacity: 0%;
 
}
.ActiveSideBar{
  font-size: 2em ; 
  transition: font-size 1s;
  color: rgb(8, 80, 170);
}
.sideBar:hover{
  font-size: 2em ; 
  transition: font-size 1s;
}
.sideBar{
  font-size: 1.5rem;
  transition: font-size 1s;
}