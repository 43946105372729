.char-market {
  justify-content: center;
  column-gap: 20px;
}
.underline {
  border: none;
  border-bottom: 1px solid black;
}
.cargo-d p:last-child {
  padding: 2px 8px;
  padding-left: 1px;
}
.cargo-d {
  margin-bottom: 20px;
  font-size: 14px;
}
:disabled{
  cursor: not-allowed;
}
